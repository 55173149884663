import React, { useState } from "react";
import "./../App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { blog, strax, sudyi, vratari, zapis } from "./products";
import { useNavigate } from "react-router-dom";

function App() {
	const [push, setPush] = useState(["arena", "school"]);
	const navigate = useNavigate();
	const openModalOtobr = () => setIsModalOtobrOpen(true);
	const closeModalOtobr = () => setIsModalOtobrOpen(false);
	const [isModalOtobrOpen, setIsModalOtobrOpen] = useState(false);
	const [isGridView, setIsGridView] = useState(true);
	const [viewMode, setViewMode] = useState("grid");
	const toggleView = () => {
		setIsGridView(!isGridView);
	};
	const handleNotification = async () => {
		const categoriesString = push.join(",");
		const url = `/api/user/setcategoriesnew?categoriesnew=${categoriesString}`;

		try {
			const response = await fetch(url, {
				method: "GET", // или 'POST' в зависимости от требований
			});

			if (response.ok) {
				console.log("Успешно отправлено!");
			} else {
				console.error("Ошибка при отправке уведомлений");
			}
		} catch (error) {
			console.error("Ошибка:", error);
		}
	};
	const [searchQuery, setSearchQuery] = useState("");
	const [sortType, setSortType] = useState(null);
	const [selectedCity, setSelectedCity] = useState("Москва");
	const [cityDropdownOpen, setCityDropdownOpen] = useState(false);
	const [ageDropdownOpen, setAgeDropdownOpen] = useState(false);
	const [floorDropdownOpen, setFloorDropdownOpen] = useState(false);
	const [formatDropdownOpen, setFormatDropdownOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalFloorOpen, setIsModalFloorOpen] = useState(false);
	const [isModalFormatOpen, setIsModalFormatOpen] = useState(false);
	const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [selectedAgeGroup, setSelectedAgeGroup] = useState("");
	const [selectedFloorGroup, setSelectedFloorGroup] = useState("");
	const [selectedFormatGroup, setSelectedFormatGroup] = useState("");
	const [priceRange, setPriceRange] = useState({ min: "0", max: "100000" });

	const [sizeRange, setSizeRange] = useState({ min: "0", max: "100" });
	const [selectedCondition, setSelectedCondition] = useState("Любое");
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedDiscount, setSelectedDiscount] = useState("");
	const cities = [
		"Москва",
		"Санкт-Петербург",
		"Новосибирск",
		"Екатеринбург",
		"Казань",
	];
	const [currentSlides, setCurrentSlides] = useState({});
	const getActiveFiltersCount = () => {
		let count = 0;
		if (selectedCategory && selectedCategory !== "Не выбрано") count++;
		if (priceRange.min && priceRange.min !== "0") count++;
		if (selectedCondition && selectedCondition !== "Любое") count++;
		if (selectedAgeGroup && selectedAgeGroup !== "") count++;
		if (selectedFloorGroup && selectedFloorGroup !== "") count++;
		if (selectedFormatGroup && selectedFormatGroup !== "") count++;
		for (let key in activeFilters) {
			if (activeFilters[key]) count++;
		}
		return count;
	};
	const settings = (productId) => ({
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		afterChange: (current) => handleSlideChange(productId, current), // передаем id товара и текущий слайд
	});
	const handleSlideChange = (productId, current) => {
		setCurrentSlides((prevState) => ({
			...prevState,
			[productId]: current,
		}));
	};
	const ageGroups = ["Текущие", "Предстоящие", "Прошедшие"];

	const floorGroups = ["Краткосрочный турнир", "Сезонный турнир (лига)"];

	const formatGroups = ["3x3", "5x5"];
	const conditions = ["Любое", "Новое", "Б/У"];
	const category = [];
	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);
	const openModalFloor = () => setIsModalFloorOpen(true);
	const closeModalFloor = () => setIsModalFloorOpen(false);
	const openModalFormat = () => setIsModalFormatOpen(true);
	const closeModalFormat = () => setIsModalFormatOpen(false);
	const openModalMenu = () => setIsModalMenuOpen(true);
	const closeModalMenu = () => setIsModalMenuOpen(false);
	const openFilterModal = () => setIsFilterModalOpen(true);
	const closeFilterModal = () => setIsFilterModalOpen(false);
	const handleApplyFilter = () => {
		closeModal();
		closeModalFloor();
		closeModalFormat();
	};
	const handleClearSelection = () => {
		setSelectedAgeGroup("");
	};
	const handleClearSelectionFloor = () => {
		setSelectedFloorGroup("");
	};
	const handleClearSelectionFormat = () => {
		setSelectedFormatGroup("");
	};
	const handleCitySelect = (city) => {
		setSelectedCity(city);
		setCityDropdownOpen(false);
	};
	const handleAgeSelect = (age) => {
		setSelectedAgeGroup(age);
		setAgeDropdownOpen(false);
	};
	const handleFloorSelect = (floor) => {
		setSelectedFloorGroup(floor);
		setFloorDropdownOpen(false);
	};
	const handleFormatSelect = (format) => {
		setSelectedFormatGroup(format);
		setFormatDropdownOpen(false);
	};
	let filteredProducts = zapis.filter(
		(product) =>
			product.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
			product.city === selectedCity &&
			(selectedAgeGroup === "" || product.ageGroup === selectedAgeGroup) &&
			(selectedFloorGroup === "" ||
				product.floorGroup === selectedFloorGroup) &&
			(selectedFormatGroup === "" ||
				product.FormatGroup === selectedFormatGroup) &&
			(selectedCondition === "Любое" ||
				product.condition === selectedCondition) &&
			(selectedCategory.length === 0 ||
				selectedCategory.includes(product.category)) &&
			(!priceRange.min || product.price >= parseInt(priceRange.min)) &&
			(!priceRange.max || product.price <= parseInt(priceRange.max)) &&
			(!sizeRange.min || product.size >= parseInt(sizeRange.min)) &&
			(!sizeRange.max || product.size <= parseInt(sizeRange.max))
	);
	if (sortType === "price-asc") {
		filteredProducts.sort((a, b) => a.price - b.price);
	} else if (sortType === "price-desc") {
		filteredProducts.sort((a, b) => b.price - a.price);
	} else if (sortType === "rating") {
		filteredProducts.sort((a, b) => b.rating - a.rating);
	} else if (sortType === "date") {
		filteredProducts.sort((a, b) => b.dat - a.dat);
	}
	const groupedProducts = [];
	for (let i = 0; i < filteredProducts.length; i += 2) {
		groupedProducts.push(filteredProducts.slice(i, i + 2));
	}
	const [isSortModalOpen, setIsSortModalOpen] = useState(false);
	const openSortModal = () => setIsSortModalOpen(true);
	const closeSortModal = () => setIsSortModalOpen(false);
	const handleApplySort = () => {
		closeSortModal();
	};
	const [isPriceDropdownOpen, setIsPriceDropdownOpen] = useState(false);
	const [isAgeDropdownOpen, setIsAgeDropdownOpen] = useState(false);
	const [isFloorDropdownOpen, setIsFloorDropdownOpen] = useState(false);
	const [isFormatDropdownOpen, setIsFormatDropdownOpen] = useState(false);
	const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
	const [isConditionDropdownOpen, setIsConditionDropdownOpen] = useState(false);
	const togglePriceDropdown = () =>
		setIsPriceDropdownOpen(!isPriceDropdownOpen);
	const toggleCategoryDropdown = () =>
		setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
	const toggleConditionDropdown = () =>
		setIsConditionDropdownOpen(!isConditionDropdownOpen);
	const [activeFilters, setActiveFilters] = useState({
		discount: false,
		online: false,
	});
	const [isFilterActive, setIsFilterActive] = useState(false);
	const handleApplyFilterModal = () => {
		if (selectedCategory.length > 0 || priceRange.min || selectedCondition) {
			setIsFilterActive(true);
		} else {
			setIsFilterActive(false);
		}
		closeFilterModal();
	};
	const handleFilterClick = (filter) => {
		setActiveFilters((prevFilters) => ({
			...prevFilters,
			[filter]: !prevFilters[filter],
		}));

		if (filter === "adults") {
			setIsModalOpen(true);
		}
	};
	const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
	const [isSizeModalOpen, setIsSizeModalOpen] = useState(false);
	const [isConditionModalOpen, setIsConditionModalOpen] = useState(false);
	const openPriceModal = () => setIsPriceModalOpen(true);
	const closePriceModal = () => setIsPriceModalOpen(false);
	const openSizeModal = () => setIsSizeModalOpen(true);
	const closeSizeModal = () => setIsSizeModalOpen(false);
	const openConditionModal = () => setIsConditionModalOpen(true);
	const closeConditionModal = () => setIsConditionModalOpen(false);
	const handleClearPriceSelection = () => setPriceRange({ min: "", max: "" });
	const handleClearSizeSelection = () => setSizeRange({ min: "", max: "" });
	const handleClearConditionSelection = () => setSelectedCondition("Любое");
	const handleApplyPriceFilter = () => {
		// Применяем фильтр по цене
		closePriceModal();
	};
	const handleApplySizeFilter = () => {
		// Применяем фильтр по цене
		closeSizeModal();
	};
	const handleApplyConditionFilter = () => {
		// Применяем фильтр по состоянию
		closeConditionModal();
	};
	const handleCategoryClick = (category) => {
		setSelectedCategory((prevSelectedCategory) => {
			if (prevSelectedCategory.includes(category)) {
				// Убираем категорию, если она уже выбрана
				return prevSelectedCategory.filter((cat) => cat !== category);
			} else {
				// Добавляем категорию, если она еще не выбрана
				return [...prevSelectedCategory, category];
			}
		});
	};

	const handleResetFilters = () => {
		setSelectedCity("Выберите город");
		setSelectedAgeGroup("Выберите категорию соревнований");
		setSelectedFloorGroup("Выберите тип соревнования");
		setSelectedFormatGroup("Выберите формат");
		setPriceRange({ min: 0, max: 10000 });
		setSizeRange({ min: 0, max: 100 });
		setSelectedCategory([]);
		setSelectedCondition("Любое");
	};

	const clearSearch = () => {
		setSearchQuery("");
	};

	return (
		<div className="container">
			<div className="step step-1">
				<div>
					<button className="back-button"></button>
					<h1>Записаться на турнир</h1>
				</div>

				<div className="icons">
					<button className="icon-button"></button>
					<button className="icon-button"></button> {/* Иконка избранного */}
					<button className="icon-button" onClick={openModalMenu}></button>{" "}
					{/* Иконка корзины */}
				</div>
			</div>

			<div className="step step-2">
				<input
					type="text"
					placeholder="Поиск"
					className="search-input"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
				<span
					className={searchQuery ? "clear-button visible" : "clear-button"}
					onClick={clearSearch}>
					Отменить
				</span>
			</div>

			{isModalMenuOpen && (
				<div className="modal">
					<div className="modal-menu">
						<div className="modal-menu-header">
							<div className="modal-stroke">
								Избранные <p>0</p>
							</div>
							<hr />
							<div className="modal-stroke">
								Размещенные <p>0</p>
							</div>
						</div>

						<button className="apply-btn" onClick={closeModalMenu}>
							Отмена
						</button>
					</div>
				</div>
			)}

			<div className="step step-3">
				<div className="custom-select">
					<span className="city">
						<span></span>
						{selectedCity}
					</span>
					<button
						className="city-button"
						onClick={() => setCityDropdownOpen(!cityDropdownOpen)}>
						<span></span> Выбрать город
					</button>
					{cityDropdownOpen && (
						<div className="dropdown">
							{cities.map((city) => (
								<button key={city} onClick={() => handleCitySelect(city)}>
									{city}
								</button>
							))}
						</div>
					)}
				</div>
			</div>

			<div className="step step-4">
				<div className="filters">
					<span
						className={`filter-button ${
							getActiveFiltersCount() > 0 ? "active" : ""
						}`}
						onClick={openFilterModal}>
						{getActiveFiltersCount() > 0 && (
							<span className="filter-count">{getActiveFiltersCount()}</span>
						)}
					</span>

					<div
						className={`filter age ${selectedAgeGroup ? "active" : ""}`}
						onClick={openModal}>
						{selectedAgeGroup ? selectedAgeGroup : "Категория"} <p></p>
					</div>

					<div
						className={`filter age ${selectedFloorGroup ? "active" : ""}`}
						onClick={openModalFloor}>
						{selectedFloorGroup ? selectedFloorGroup : "Тип соревнования"}{" "}
						<p></p>
					</div>

					<div
						className={`filter age ${selectedFormatGroup ? "active" : ""}`}
						onClick={openModalFormat}>
						{selectedFormatGroup ? selectedFormatGroup : "Формат"} <p></p>
					</div>

					{/* Отображаем возможные фильтры, исключая "Не выбрано" */}
					{category
						.filter((category) => category !== "Не выбрано")
						.map((category) => (
							<div
								key={category}
								className={`filter ${
									selectedCategory.includes(category) ? "active" : ""
								}`}
								onClick={() => handleCategoryClick(category)}>
								{category}
							</div>
						))}

					<div className="filter age" onClick={openPriceModal}>
						Стоимость <p></p>
					</div>
				</div>
			</div>

			{isPriceModalOpen && (
				<div className="modal">
					<div className="modal-content">
						<div className="modal-header">
							<button
								className="back-button"
								onClick={closePriceModal}></button>
							<h2>Стоимость</h2>
							<button className="clear-btn" onClick={handleClearPriceSelection}>
								Очистить
							</button>
						</div>
						<div
							className="modal-body"
							style={{ display: "flex", flexDirection: "column", gap: 8 }}>
							<div className="price-range">
								<input
									type="number"
									value={priceRange.min}
									onChange={(e) =>
										setPriceRange({ ...priceRange, min: e.target.value })
									}
									placeholder="От 0"
								/>
								<input
									type="number"
									value={priceRange.max}
									onChange={(e) =>
										setPriceRange({ ...priceRange, max: e.target.value })
									}
									placeholder="До 100000"
								/>
							</div>
							<div className="price-slider-container">
								<input
									type="range"
									min="0"
									max="50000"
									value={priceRange.min}
									onChange={(e) =>
										setPriceRange({
											...priceRange,
											min: Math.min(Number(e.target.value), priceRange.max - 1),
										})
									}
									className="price-slider"
								/>
								<input
									type="range"
									min="50000"
									max="100000"
									value={priceRange.max}
									onChange={(e) =>
										setPriceRange({
											...priceRange,
											max: Math.max(Number(e.target.value), priceRange.min + 1),
										})
									}
									className="price-slider"
								/>
							</div>
						</div>
						<div className="modal-footer">
							<button className="apply-btn" onClick={handleApplyPriceFilter}>
								Применить фильтр
							</button>
						</div>
					</div>
				</div>
			)}

			{isSizeModalOpen && (
				<div className="modal">
					<div className="modal-content">
						<div className="modal-header">
							<button className="back-button" onClick={closeSizeModal}></button>
							<h2>Размеры</h2>
							<button className="clear-btn" onClick={handleClearSizeSelection}>
								Очистить
							</button>
						</div>
						<div
							className="modal-body"
							style={{ display: "flex", flexDirection: "column", gap: 8 }}>
							<div
								className="price-range"
								style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
								<label htmlFor="min-price">Цена от:</label>
								<input
									type="number"
									id="min-price"
									value={sizeRange.min}
									onChange={(e) =>
										setSizeRange({ ...sizeRange, min: e.target.value })
									}
								/>
							</div>
							<div
								className="price-range"
								style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
								<label htmlFor="max-price">Цена до:</label>
								<input
									type="number"
									id="max-price"
									value={sizeRange.max}
									onChange={(e) =>
										setSizeRange({ ...sizeRange, max: e.target.value })
									}
								/>
							</div>
						</div>
						<div className="modal-footer">
							<button className="apply-btn" onClick={handleApplySizeFilter}>
								Применить фильтр
							</button>
						</div>
					</div>
				</div>
			)}

			{isFilterModalOpen && (
				<div className="modal modal-fullscreen">
					<div className="modal-content modal-content-filter">
						<div className="modal-header">
							<button
								className="back-button"
								onClick={closeFilterModal}></button>
							<h2 className="modal-title">Фильтры</h2>

							<a
								href=""
								onClick={handleResetFilters}
								className="clear-btn"
								style={{ textDecoration: "none" }}>
								Очистить
							</a>
						</div>

						<div className="modal-body">
							<label style={{ fontWeight: 700 }}>Выбрать город</label>
							<div
								style={{ marginBottom: 25, marginTop: 16 }}
								className="filter-group">
								<div className="custom-select">
									<div className="dropdown-container">
										<div
											className="dropdown-trigger"
											onClick={() => setCityDropdownOpen(!cityDropdownOpen)}>
											<span className="dropdown-label">{selectedCity}</span>
											<span className="dropdown-value"></span>
										</div>
									</div>
									{cityDropdownOpen && (
										<div className="dropdown">
											{cities.map((city) => (
												<button
													key={city}
													onClick={() => handleCitySelect(city)}>
													{city}
												</button>
											))}
										</div>
									)}
								</div>
							</div>
							<label style={{ fontWeight: 700 }}>Категория</label>
							<div
								style={{ marginBottom: 25, marginTop: 16 }}
								className="filter-group">
								<div className="custom-select">
									<div className="dropdown-container">
										<div
											className="dropdown-trigger"
											onClick={() => setAgeDropdownOpen(!ageDropdownOpen)}>
											<span className="dropdown-label">
												{selectedAgeGroup
													? selectedAgeGroup
													: "Выберите категорию"}
											</span>
											<span className="dropdown-value"></span>
										</div>
									</div>
									{ageDropdownOpen && (
										<div className="dropdown">
											{ageGroups.map((age) => (
												<button
													key={age}
													onClick={() => setSelectedAgeGroup(age)}>
													{age}
												</button>
											))}
										</div>
									)}
								</div>
							</div>
							<label style={{ fontWeight: 700 }}>Тип соревнования</label>
							<div
								style={{ marginBottom: 25, marginTop: 16 }}
								className="filter-group">
								<div className="custom-select">
									<div className="dropdown-container">
										<div
											className="dropdown-trigger"
											onClick={() => setFloorDropdownOpen(!floorDropdownOpen)}>
											<span className="dropdown-label">
												{selectedFloorGroup
													? selectedFloorGroup
													: "Выберите тип соревнования"}
											</span>
											<span className="dropdown-value"></span>
										</div>
									</div>
									{floorDropdownOpen && (
										<div className="dropdown">
											{floorGroups.map((floor) => (
												<button
													key={floor}
													onClick={() => setSelectedFloorGroup(floor)}>
													{floor}
												</button>
											))}
										</div>
									)}
								</div>
							</div>

							<label style={{ fontWeight: 700 }}>Формат</label>
							<div
								style={{ marginBottom: 25, marginTop: 16 }}
								className="filter-group">
								<div className="custom-select">
									<div className="dropdown-container">
										<div
											className="dropdown-trigger"
											onClick={() =>
												setFormatDropdownOpen(!formatDropdownOpen)
											}>
											<span className="dropdown-label">
												{selectedFormatGroup
													? selectedFormatGroup
													: "Выберите формат"}
											</span>
											<span className="dropdown-value"></span>
										</div>
									</div>
									{formatDropdownOpen && (
										<div className="dropdown">
											{formatGroups.map((format) => (
												<button
													key={format}
													onClick={() => setSelectedFormatGroup(format)}>
													{format}
												</button>
											))}
										</div>
									)}
								</div>
							</div>

							<div
								className="filter-group"
								style={{
									display: "flex",
									flexDirection: "column",
									gap: 16,
									marginBottom: 25,
								}}>
								<label style={{ fontWeight: 700 }}>Стоимость</label>
								<div className="price-range">
									<input
										type="number"
										value={priceRange.min}
										onChange={(e) =>
											setPriceRange({ ...priceRange, min: e.target.value })
										}
										placeholder="От 0"
									/>
									<input
										type="number"
										value={priceRange.max}
										onChange={(e) =>
											setPriceRange({ ...priceRange, max: e.target.value })
										}
										placeholder="До 100000"
									/>
								</div>
								<div className="price-slider-container">
									<input
										type="range"
										min="0"
										max="50000"
										value={priceRange.min}
										onChange={(e) =>
											setPriceRange({
												...priceRange,
												min: Math.min(
													Number(e.target.value),
													priceRange.max - 1
												),
											})
										}
										className="price-slider"
									/>
									<input
										type="range"
										min="50000"
										max="100000"
										value={priceRange.max}
										onChange={(e) =>
											setPriceRange({
												...priceRange,
												max: Math.max(
													Number(e.target.value),
													priceRange.min + 1
												),
											})
										}
										className="price-slider"
									/>
								</div>
							</div>

							<button
								className="apply-btn"
								onClick={handleApplyFilterModal}
								style={{ marginTop: 16 }}>
								Применить фильтр
							</button>
						</div>
					</div>
				</div>
			)}

			{isModalOpen && (
				<div className="modal">
					<div className="modal-content">
						<div className="modal-header">
							<button className="back-button" onClick={closeModal}></button>
							<h2>Категория</h2>
							<button className="clear-btn" onClick={handleClearSelection}>
								Очистить
							</button>
						</div>
						<div className="modal-body">
							{ageGroups.map((group) => (
								<div
									key={group}
									style={{ flexDirection: "row" }}
									className="radio-group">
									<input
										type="radio"
										id={group}
										name="ageGroup"
										value={group}
										checked={selectedAgeGroup === group}
										onChange={(e) => setSelectedAgeGroup(e.target.value)}
									/>
									<label htmlFor={group}>{group}</label>
								</div>
							))}
						</div>
						<div className="modal-footer">
							<button className="apply-btn" onClick={handleApplyFilter}>
								Применить фильтр
							</button>
						</div>
					</div>
				</div>
			)}

			{isModalFloorOpen && (
				<div className="modal">
					<div className="modal-content">
						<div className="modal-header">
							<button
								className="back-button"
								onClick={closeModalFloor}></button>
							<h2>Тип соревнования</h2>
							<button className="clear-btn" onClick={handleClearSelectionFloor}>
								Очистить
							</button>
						</div>
						<div className="modal-body">
							{floorGroups.map((group) => (
								<div
									key={group}
									style={{ flexDirection: "row" }}
									className="radio-group">
									<input
										type="radio"
										id={group}
										name="floorGroup"
										value={group}
										checked={selectedFloorGroup === group}
										onChange={(e) => setSelectedFloorGroup(e.target.value)}
									/>
									<label htmlFor={group}>{group}</label>
								</div>
							))}
						</div>
						<div className="modal-footer">
							<button className="apply-btn" onClick={handleApplyFilter}>
								Применить фильтр
							</button>
						</div>
					</div>
				</div>
			)}
			{isModalFormatOpen && (
				<div className="modal">
					<div className="modal-content">
						<div className="modal-header">
							<button
								className="back-button"
								onClick={closeModalFormat}></button>
							<h2>Формат</h2>
							<button
								className="clear-btn"
								onClick={handleClearSelectionFormat}>
								Очистить
							</button>
						</div>
						<div className="modal-body">
							{formatGroups.map((group) => (
								<div
									key={group}
									style={{ flexDirection: "row" }}
									className="radio-group">
									<input
										type="radio"
										id={group}
										name="formatGroup"
										value={group}
										checked={selectedFormatGroup === group}
										onChange={(e) => setSelectedFormatGroup(e.target.value)}
									/>
									<label htmlFor={group}>{group}</label>
								</div>
							))}
						</div>
						<div className="modal-footer">
							<button className="apply-btn" onClick={handleApplyFilter}>
								Применить фильтр
							</button>
						</div>
					</div>
				</div>
			)}

			{isSortModalOpen && (
				<div className="modal">
					<div className="modal-content">
						<div className="modal-header">
							<button className="back-button" onClick={closeSortModal}></button>
							<h2>Сортировка</h2>
							<button className="clear-btn" onClick={() => setSortType(null)}>
								Очистить
							</button>
						</div>
						<div className="modal-body">
							<div className="radio-group" style={{ flexDirection: "row" }}>
								<input
									type="radio"
									id="price-asc"
									name="sortType"
									value="price-asc"
									checked={sortType === "price-asc"}
									onChange={(e) => setSortType(e.target.value)}
								/>
								<label style={{ marginLeft: "0" }} htmlFor="price-asc">
									{" "}
									Cначала дешевле
								</label>
							</div>
							<div className="radio-group" style={{ flexDirection: "row" }}>
								<input
									type="radio"
									id="price-desc"
									name="sortType"
									value="price-desc"
									checked={sortType === "price-desc"}
									onChange={(e) => setSortType(e.target.value)}
								/>
								<label style={{ marginLeft: "0" }} htmlFor="price-desc">
									{" "}
									Cначала дороже
								</label>
							</div>
							<div className="radio-group" style={{ flexDirection: "row" }}>
								<input
									type="radio"
									id="date"
									name="sortType"
									value="date"
									checked={sortType === "date"}
									onChange={(e) => setSortType(e.target.value)}
								/>
								<label style={{ marginLeft: "0" }} htmlFor="price-desc">
									По дате
								</label>
							</div>
							<div className="radio-group" style={{ flexDirection: "row" }}>
								<input
									type="radio"
									id="rating"
									name="sortType"
									value="rating"
									checked={sortType === "rating"}
									onChange={(e) => setSortType(e.target.value)}
								/>
								<label style={{ marginLeft: "0" }} htmlFor="rating">
									По рейтингу
								</label>
							</div>
						</div>
						<div className="modal-footer">
							<button className="apply-btn" onClick={handleApplySort}>
								Применить сортировку
							</button>
						</div>
					</div>
				</div>
			)}

			<div className="step step-5">
				<div className="custom-select">
					<button className="sort-button" onClick={openSortModal}>
						<span></span> Сортировка
					</button>
				</div>
				<button className="notify-button" onClick={openModalOtobr}>
					<span></span> Формат отображения
				</button>
			</div>
			{isModalOtobrOpen && (
				<div className="modal">
					<div className="modal-menu">
						<div
							className="modal-menu-header slsl"
							style={{ height: "max-content" }}>
							<h2>Отображение объявлений</h2>
							<div
								className="modal-stroke"
								style={{
									display: "flex",
									flexDirection: "row",
									gap: "8px",
									justifyContent: "inherit",
								}}>
								<input
									type="radio"
									id="grid-view"
									name="viewMode"
									value="grid"
									checked={viewMode === "grid"}
									onChange={() => setViewMode("grid")}
								/>
								<label>
									Сеткой <p></p>
								</label>
							</div>
							<div
								className="modal-stroke"
								style={{
									display: "flex",
									flexDirection: "row",
									gap: "8px",
									justifyContent: "inherit",
								}}>
								<input
									type="radio"
									id="solo-view"
									name="soloMode"
									value="solo"
									checked={viewMode === "solo"}
									onChange={() => setViewMode("solo")}
								/>
								<label>
									По одному <p></p>
								</label>
							</div>
							<div
								className="modal-stroke"
								style={{
									display: "flex",
									flexDirection: "row",
									gap: "8px",
									justifyContent: "inherit",
								}}>
								<input
									type="radio"
									id="list-view"
									name="viewMode"
									value="list"
									checked={viewMode === "list"}
									onChange={() => setViewMode("list")}
								/>
								<label>
									Вертикально <p></p>
								</label>
							</div>
						</div>

						<button className="apply-btn" onClick={closeModalOtobr}>
							Отмена
						</button>
					</div>
				</div>
			)}
			<div
				className={`product-scroll-container ${
					viewMode === "grid"
						? "grid-view"
						: viewMode === "list"
						? "list-view"
						: "solo-view"
				}`}>
				<div className="products-row">
					{groupedProducts.length > 0 ? (
						groupedProducts.map((group, index) => (
							<div className="product-column" key={index}>
								{group.map((product) => (
									<div
										className="product"
										key={product.id}
										onClick={() => {
											console.log(
												"Navigating to product:",
												product.id,
												"with products:",
												groupedProducts
											);
											navigate(`/tournament/${product.id}`, {
												state: { products: groupedProducts },
											});
										}}>
										<Slider {...settings(product.id)}>
											{product.images &&
												product.images.map((image, i) => (
													<img
														src={image}
														alt={`Product Image ${i + 1}`}
														key={i}
													/>
												))}
										</Slider>
										<div className="slider-counter">
											{(currentSlides[product.id] || 0) + 1} из{" "}
											{product.images.length}
										</div>

										<div className="product-info" style={{ marginTop: -4 }}>
											<h2>{product.name}</h2>
											<p>
												от {product.price}р{" "}
												<span className="old-price">{product.oldPrice}р</span>
											</p>
											{/*<p style={{fontWeight:400, fontSize:14}}>{product.ageGroup}</p>*/}
											<p style={{ fontSize: 14, fontWeight: 400 }}>
												{product.data}
											</p>
											<p>{product.ageGroup}</p>
											{/*<p>{product.indi}</p>*/}
											<p className="rating">
												<span>{product.rating}</span> (0)
											</p>
											<span className="product-izb"></span>
										</div>
									</div>
								))}
							</div>
						))
					) : (
						<div className="no-products">
							<p>По выбранным фильтрам объявлений нет</p>
						</div>
					)}
				</div>
			</div>

			<h1 style={{ marginTop: 16, marginBottom: 16 }}>Рекомендуем также</h1>
			<div className="cards" style={{ marginBottom: 20 }}>
				<div className="card spons">
					<span>Поиск спонсоров</span>
					<div className="card__img"></div>
				</div>
				<div className="card ispol">
					<span>Поиск исполнителей</span>
					<div className="card__img"></div>
				</div>
			</div>

			<div className="step step-1">
				<h1>Хоккейные сборы</h1>

				<button className="back-button  rotate"></button>
			</div>

			<div className="product-scroll-container horizontal">
				<div className="products-row">
					{sudyi.map((product) => (
						<div className="product-column" key={product.id}>
							<div className="product">
								<Slider {...settings}>
									{product.images &&
										product.images.map((image, i) => (
											<div key={i} className="sadas2">
												<img src={image} alt={`Product Image ${i + 1}`} />
												<div className="slider-counter">
													{(currentSlides[product.id] || 0) + 1} из{" "}
													{product.images.length}
												</div>
											</div>
										))}
								</Slider>
								<div className="product-info">
									<h2>{product.name}</h2>
									<p>
										от {product.price}р{" "}
										<span className="old-price">{product.oldPrice}р</span>
									</p>
									{/*<p style={{fontWeight:400, fontSize:14}}>{product.ageGroup}</p>*/}
									<p style={{ fontSize: 14, fontWeight: 400 }}>
										{product.ageGroup}
									</p>
									<p>{product.floorGroup}</p>
									{/*<p>{product.indi}</p>*/}
									<p className="rating">
										<span>{product.rating}</span> (0)
									</p>
									<span className="product-izb"></span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="step step-1">
				<h1>Страхование</h1>

				<button className="back-button  rotate"></button>
			</div>

			<div className="product-scroll-container horizontal">
				<div className="products-row">
					{strax.map((product) => (
						<div className="product-column" key={product.id}>
							<div className="product">
								<Slider {...settings}>
									{product.images &&
										product.images.map((image, i) => (
											<div key={i} className="sadas2">
												<img src={image} alt={`Product Image ${i + 1}`} />
												<div className="slider-counter">
													{(currentSlides[product.id] || 0) + 1} из{" "}
													{product.images.length}
												</div>
											</div>
										))}
								</Slider>
								<div className="product-info">
									<h2>{product.name}</h2>
									{/*<p style={{fontWeight:400, fontSize:14}}>{product.ageGroup}</p>*/}
									<p style={{ fontSize: 14, fontWeight: 400 }}>
										{product.location}
									</p>
									{/*<p>{product.indi}</p>*/}
									<p className="rating">
										<span>{product.rating}</span> (0)
									</p>
									<span className="product-izb"></span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="step step-1">
				<h1>Блогеры</h1>

				<button className="back-button  rotate"></button>
			</div>

			<div className="product-scroll-container horizontal">
				<div className="products-row">
					{blog.map((product) => (
						<div className="product-column" key={product.id}>
							<div className="product">
								<Slider {...settings}>
									{product.images &&
										product.images.map((image, i) => (
											<div key={i} className="sadas2">
												<img src={image} alt={`Product Image ${i + 1}`} />
												<div className="slider-counter">
													{(currentSlides[product.id] || 0) + 1} из{" "}
													{product.images.length}
												</div>
											</div>
										))}
								</Slider>
								<div className="product-info">
									<h2>{product.name}</h2>
									{/*<p style={{fontWeight:400, fontSize:14}}>{product.ageGroup}</p>*/}
									<p style={{ fontSize: 14, fontWeight: 400 }}>
										Подписчиков: {product.sub}
									</p>
									{/*<p>{product.indi}</p>*/}
									<p className="rating">
										<span>{product.rating}</span> (0)
									</p>
									<span className="product-izb"></span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default App;
