import styles from "./FunctionalButton.module.css";

export default function FunctionalButton({children, onClick}) {
    const handleClick = (event) => {
        event.preventDefault();
        if(onClick) onClick();
    }
    return (
        <button className={styles.functionalButton} onClick={handleClick}>
            {children}
        </button>
    )
}