"use client";

import "./Authorization.css";
import { useEffect, useState } from "react";
import FunctionalButton from "../ui/FunctionalButton/FunctionalButton";
import DefaultInput from "../ui/DefaultInput/DefaultInput";
import { registerUserAction, logUserAction } from "../../actions/user";

export default function AuthorizationPopup({ isOpen, toggle }) {
	const [mode, setMode] = useState("login");
	const [preloaderVisible, setPreloaderVisible] = useState(false);
	const [successPopupVisibility, setSuccessPopupVisibility] = useState(false);

	const [agreementChecked, setAgreementChecked] = useState(false);
	const [agreementMessage, setAgreementMessage] = useState("");

	const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);
	const [isEmailCorrect, setIsEmailCorrect] = useState(true);
	const [isBirthDateCorrect, setIsBirthDateCorrect] = useState(true);

	const [responseMessage, setResponseMessage] = useState({
		message: "",
		mode: "",
	});

	const [authData, setAuthData] = useState({
		logEmail: "",
		logPassword: "",
		regEmail: "",
		regPassword: "",
		regName: "",
		regSurname: "",
		regPhone: "",
		regBirthDate: "",
	});

	const [authMessages, setAuthMessages] = useState({
		emailMessage: "",
		passwordMessage: "",
		regEmailMessage: "",
		regPasswordMessage: "",
		nameMessage: "",
		surnameMessage: "",
		phoneMessage: "",
		birthDateMessage: "",
	});

	const currentDate = new Date();

	const toggleMode = (mode) => {
		setMode(mode);
	};

	const checkAgreement = () => {
		setAgreementChecked(!agreementChecked);
	};

	const getBack = () => {
		// Если пользователь заходит с телефона,
		// то кнопка этого компонента в секциях регистрации и входа не закрывает компонент,
		// а открывает секцию выбора (зарегистрироваться или войти),
		// а уже в этой секции кнопка закрытия делает то, что должна по названию
		if (window.innerWidth <= 570 && mode != "choose") {
			setMode("choose");
		} else {
			toggle(false);
		}
	};

	const validateName = (validName) => {
		if (validName === "") {
			setAuthMessages((prev) => ({
				...prev,
				nameMessage: "Заполните обязательное поле",
			}));

			return false;
		}

		setAuthMessages((prev) => ({ ...prev, nameMessage: "" }));
		return true;
	};

	const validateSurname = (validName) => {
		if (validName === "") {
			setAuthMessages((prev) => ({
				...prev,
				surnameMessage: "Заполните обязательное поле",
			}));

			return false;
		}

		setAuthMessages((prev) => ({ ...prev, surnameMessage: "" }));
		return true;
	};

	const validateRegEmail = (validEmail) => {
		const emailPattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
		if (validEmail === "") {
			setAuthMessages((prev) => ({
				...prev,
				regEmailMessage: "Заполните обязательное поле",
			}));
			setIsEmailCorrect(false);
			return false;
		}

		if (!validEmail.includes("@")) {
			setAuthMessages((prev) => ({
				...prev,
				regEmailMessage: 'Адрес электронной почты должен содержать символ "@"',
			}));
			setIsEmailCorrect(false);
			return false;
		}

		if (!emailPattern.test(validEmail)) {
			setAuthMessages((prev) => ({
				...prev,
				regEmailMessage: "Неверный адрес электронной почты",
			}));
			setIsEmailCorrect(false);
			return false;
		}

		setAuthMessages((prev) => ({ ...prev, regEmailMessage: "" }));
		setIsEmailCorrect(true);
		return true;
	};

	const validateRegPassword = (validPassword) => {
		const passwordPattern = /^(?=.*[A-Z])(?=.*\d).+$/;

		const passwordPatternSymbol = /.*[!@#$%^&*()-+=\\[\]{};:'",<.>/?].*/;
		if (validPassword === "") {
			setAuthMessages((prev) => ({
				...prev,
				regPasswordMessage: "Заполните обязательное поле",
			}));
			return false;
		}
		if (validPassword.length < 6) {
			setAuthMessages((prev) => ({
				...prev,
				regPasswordMessage: "Пароль должен быть не короче 6 символов",
			}));
			return false;
		} else if (!passwordPatternSymbol.test(validPassword)) {
			setAuthMessages((prev) => ({
				...prev,
				regPasswordMessage:
					"Пароль должен включать спецсимвол (например: $, %, #, &, *, ! и т.д.)",
			}));
			return false;
		} else if (!passwordPattern.test(validPassword)) {
			setAuthMessages((prev) => ({
				...prev,
				regPasswordMessage:
					"Пароль должен включать хотя бы одну заглавную букву и хотя бы одну цифру",
			}));
			return false;
		}

		setAuthMessages((prev) => ({ ...prev, regPasswordMessage: "" }));
		return true;
	};

	const validatePhone = (validPhone) => {
		if (validPhone === "") {
			setAuthMessages((prev) => ({
				...prev,
				phoneMessage: "Заполните обязательное поле",
			}));
			setIsPhoneCorrect(false);
			return false;
		}

		if (validPhone.length < 10) {
			setAuthMessages((prev) => ({
				...prev,
				phoneMessage: `Осталось указать цифр: ${10 - validPhone.length} из 10`,
			}));
			setIsPhoneCorrect(false);
			return false;
		}

		setAuthMessages((prev) => ({ ...prev, phoneMessage: "" }));
		setIsPhoneCorrect(true);
		return true;
	};

	const validateBirthDate = (validDate) => {
		if (validDate.length <= 0) {
			setAuthMessages((prev) => ({
				...prev,
				birthDateMessage: "Заполните обязательное поле",
			}));
			setIsBirthDateCorrect(false);
			return false;
		}

		const formattedData = validDate.split(".");
		if (formattedData.length !== 3) {
			setAuthMessages((prev) => ({
				...prev,
				birthDateMessage: "Укажите корректную дату",
			}));
			setIsBirthDateCorrect(false);
			return false;
		}

		const [day, month, year] = formattedData.map(Number);
		const inputDate = new Date(year, month - 1, day);

		if (
			isNaN(inputDate) ||
			inputDate.getFullYear() !== year ||
			inputDate.getMonth() !== month - 1 ||
			inputDate.getDate() !== day ||
			String(year).length !== 4 ||
			inputDate > currentDate
		) {
			setAuthMessages((prev) => ({
				...prev,
				birthDateMessage: "Укажите корректную дату",
			}));
			setIsBirthDateCorrect(false);
			return false;
		}

		setAuthMessages((prev) => ({
			...prev,
			birthDateMessage: "",
		}));
		setIsBirthDateCorrect(true);
		return true;
	};

	const handleDataInput = (event, validateFunc) => {
		const { name, value } = event.target;
		setAuthData((prev) => ({ ...prev, [name]: value }));

		if (name === "regPhone") {
			const phoneNumber = value.replace(/\D/g, "");

			const formatted = phoneNumber
				.replace(/^(\d{3})(\d{3})(\d{2})(\d{2}).*/, "$1 $2 $3 $4")
				.trim();

			setAuthData((prev) => ({ ...prev, [name]: formatted }));
		}
		if (name === "regBirthDate") {
			const dateNumber = value.replace(/\D/g, "");

			const formatted = dateNumber
				.replace(/^(\d{1,2})(\d{0,2})(\d{0,4})/, (match, day, month, year) => {
					if (month) month = "." + month;
					if (year) year = "." + year;
					return day + month + year;
				})
				.trim();

			setAuthData((prev) => ({ ...prev, [name]: formatted }));
		}
		if (validateFunc) validateFunc(value);
	};

	const logValidation = () => {
		if (authData.logEmail == "") {
			setAuthMessages((prev) => ({
				...prev,
				emailMessage: "Заполните обязательное поле",
			}));
			return false;
		} else {
			setAuthMessages((prev) => ({ ...prev, emailMessage: "" }));
		}

		if (authData.logPassword == "") {
			setAuthMessages((prev) => ({
				...prev,
				passwordMessage: "Заполните обязательное поле",
			}));
			return false;
		} else {
			setAuthMessages((prev) => ({ ...prev, passwordMessage: "" }));
		}

		logUser();
	};

	const regValidation = () => {
		validateName(authData.regName);
		validateSurname(authData.regSurname);
		validatePhone(authData.regPhone);
		validateRegEmail(authData.regEmail);
		validateRegPassword(authData.regPassword);
		validateBirthDate(authData.regBirthDate);

		if (!agreementChecked) {
			setAgreementMessage("Необходимо принять правила платформы");
		} else {
			setAgreementMessage("");
		}

		if (
			validateName(authData.regName) &&
			validateSurname(authData.regSurname) &&
			validateRegEmail(authData.regEmail) &&
			validateRegPassword(authData.regPassword) &&
			validatePhone(authData.regPhone) &&
			validateBirthDate(authData.regBirthDate) &&
			agreementChecked
		) {
			registerUser();
		}
	};

	const registerUser = async () => {
		setPreloaderVisible(true);
		setResponseMessage({ ["message"]: "", ["mode"]: mode });

		registerUserAction(authData)
			.then((response) => {
				if (response.status === 200) {
					setPreloaderVisible(false);
					setSuccessPopupVisibility(true);

					setAuthData({
						logEmail: "",
						logPassword: "",
						regEmail: "",
						regPassword: "",
						regName: "",
						regSurname: "",
						regPhone: "",
						regBirthDate: "",
					});
				}
			})
			.catch((error) => {
				setPreloaderVisible(false);
				if (error.response && error.response.data) {
					const isDuplicateEmail = error.response.data.errors.some(
						(error) => error.code === "DuplicateEmail"
					);

					if (isDuplicateEmail) {
						setResponseMessage((prev) => ({
							...prev,
							["message"]:
								"Пользователь с такой электронной почтой уже существует",
						}));
					}
				} else {
					setResponseMessage((prev) => ({
						...prev,
						["message"]: "Произошла ошибка при регистрации (ошибка сервера)",
					}));
				}
			});
	};

	const logUser = async () => {
		setPreloaderVisible(true);
		setResponseMessage({ ["message"]: "", ["mode"]: mode });

		logUserAction(authData)
			.then((response) => {
				if (response.status === 200) {
					setPreloaderVisible(false);
					localStorage.setItem("token", response.data);
					toggle(false);
				}
			})
			.catch((error) => {
				setPreloaderVisible(false);
				if (error.response && error.response.data) {
					setResponseMessage((prev) => ({
						...prev,
						["message"]: error.response.data.message,
					}));
				} else {
					setResponseMessage((prev) => ({
						...prev,
						["message"]:
							"Произошла ошибка при входе в аккаунт (ошибка сервера)",
					}));
				}
			});
	};

	useEffect(() => {
		// Если пользователь заходит с телефона,
		// то при открытии этого компонента открывает секцию выбора (зарегистрироваться или войти) вместо дефолтной секции
		// (по дефолту открывается секция "войти")
		if (window.innerWidth <= 570) {
			setMode("choose");
		}
	}, []);

	return (
		<div
			className={`authorization-popup__wrapper ${
				!isOpen ? "block_hidden" : ""
			}`}>
			<div
				className="authorization-popup__popup-tint"
				onClick={() => toggle(false)}></div>
			<div className="authorization-popup">
				<span
					className="authorization-popup__close-button"
					onClick={() => getBack()}></span>
				{preloaderVisible && (
					<div className="authorization-popup__loader-wrapper">
						<div className="authorization-popup__loader"></div>
					</div>
				)}

				{mode === "login" ? (
					<div className="authorization-popup__section">
						<h1>Войти</h1>
						<form>
							<DefaultInput
								id={"logEmail"}
								name={"logEmail"}
								type={"email"}
								value={authData.logEmail}
								label={"Электронная почта"}
								placeholder={"example@example.com"}
								isRequired={true}
								onChange={(e) => handleDataInput(e)}
								message={authMessages.emailMessage}
							/>
							<DefaultInput
								id={"logPassword"}
								name={"logPassword"}
								type={"password"}
								value={authData.logPassword}
								label={"Пароль"}
								placeholder={"Пароль"}
								isRequired={true}
								onChange={(e) => handleDataInput(e)}
								message={authMessages.passwordMessage}
							/>

							{responseMessage.mode === "login" &&
								responseMessage.message != "" && (
									<p className="authorization-popup__section_agreement-message">
										{responseMessage.message}
									</p>
								)}

							<a className="authorization-popup__section__forgot-password">
								Забыли пароль?
							</a>
							<FunctionalButton onClick={logValidation}>Войти</FunctionalButton>
						</form>

						<a
							className="authorization-popup__section__alternative"
							onClick={() => toggleMode("registration")}>
							Зарегистрироваться
						</a>
					</div>
				) : mode === "registration" ? (
					<div className="authorization-popup__section">
						{!successPopupVisibility && (
							<>
								<h1>
									<span onClick={() => toggleMode("login")}></span>Регистрация
								</h1>
								<form>
									<div className="authorization-popup__section-name">
										<DefaultInput
											id={"regName"}
											name={"regName"}
											type={"text"}
											value={authData.regName}
											label={"Имя"}
											placeholder={"Имя"}
											isRequired={true}
											onChange={(e) => handleDataInput(e, validateName)}
											message={authMessages.nameMessage}
										/>
										<DefaultInput
											id={"regSurname"}
											name={"regSurname"}
											type={"text"}
											value={authData.regSurname}
											label={"Фамилия"}
											placeholder={"Фамилия"}
											isRequired={true}
											onChange={(e) => handleDataInput(e, validateSurname)}
											message={authMessages.surnameMessage}
										/>
									</div>

									<DefaultInput
										id={"regPhone"}
										name={"regPhone"}
										type={"text"}
										value={authData.regPhone}
										label={"Номер телефона"}
										placeholder={"000 000 00 00"}
										isRequired={true}
										hint={`
									<div class="authorization-popup__phone-hint">
									<span>+7</span>
									<img src="/images/buttons/caret_down.svg" alt="caret"/>
									</div>
									`}
										isCorrect={isPhoneCorrect}
										maxLength={10}
										onChange={(e) => handleDataInput(e, validatePhone)}
										message={authMessages.phoneMessage}
									/>
									<DefaultInput
										id={"regEmail"}
										name={"regEmail"}
										type={"email"}
										value={authData.regEmail}
										label={"Электронная почта"}
										placeholder={"example@example.com"}
										isRequired={true}
										isCorrect={isEmailCorrect}
										onChange={(e) => handleDataInput(e, validateRegEmail)}
										message={authMessages.regEmailMessage}
									/>
									<DefaultInput
										id={"regBirthDate"}
										name={"regBirthDate"}
										type={"text"}
										// min="1900-01-01"
										// max={`${currentDate.getFullYear()}-${
										// 	currentDate.getMonth() + 1
										// }-${currentDate.getDate()}`}
										maxLength={10}
										value={authData.regBirthDate}
										label={"Дата рождения"}
										placeholder={"__.__.____"}
										isRequired={true}
										isCorrect={isBirthDateCorrect}
										pattern="\d{2}/\d{2}/\d{4}"
										// onFocus={(e) => (e.target.type = "date")}
										// onBlur={(e) => (e.target.type = "text")}
										onChange={(e) => handleDataInput(e, validateBirthDate)}
										message={authMessages.birthDateMessage}
									/>
									<DefaultInput
										id={"regPassword"}
										name={"regPassword"}
										type={"password"}
										value={authData.regPassword}
										label={"Пароль"}
										placeholder={"Пароль"}
										isRequired={true}
										onChange={(e) => handleDataInput(e, validateRegPassword)}
										message={authMessages.regPasswordMessage}
									/>

									<label className="authorization-popup__agreement">
										<input
											type="checkbox"
											name="agreement"
											id="agreement"
											checked={agreementChecked}
											onChange={checkAgreement}
										/>
										<span className="authorization-popup__agreement__checkmark"></span>
										<p>
											Используя приложение, Вы соглашаетесь с{" "}
											<a href="#">Политикой пользования сервисом</a>,{" "}
											<a href="#">Политикой конфиденциальности</a> и даете{" "}
											<a href="#">Согласие на обработку персональных данных</a>.
										</p>
									</label>

									{agreementMessage != "" && (
										<p className="authorization-popup__section_agreement-message reg">
											{agreementMessage}
										</p>
									)}

									{responseMessage.mode === "registration" &&
										responseMessage.message != "" && (
											<p className="authorization-popup__section_agreement-message reg">
												{responseMessage.message}
											</p>
										)}

									<FunctionalButton onClick={regValidation}>
										Зарегистрироваться
									</FunctionalButton>
								</form>

								<p className="authorization-popup__section__alternative">
									Уже есть аккаунт?{" "}
									<a onClick={() => toggleMode("login")}>Войти</a>
								</p>
							</>
						)}

						<SuccessfulRegistrationMessage
							successPopupVisibility={successPopupVisibility}
							setSuccessPopupVisibility={setSuccessPopupVisibility}
							toggle={toggle}
						/>
					</div>
				) : (
					mode === "choose" && (
						<div className="authorization-popup__section">
							<h1>Профиль</h1>
							<img
								className="authorization-popup__section_pick-auth-image"
								src="images/user/pick_auth.svg"
								alt="pick_auth"
							/>
							<FunctionalButton onClick={() => toggleMode("registration")}>
								<span></span>
								<p>Зарегистрироваться</p>
							</FunctionalButton>
							<p className="authorization-popup__section__alternative-mobile">
								Уже есть аккаунт?{" "}
								<a onClick={() => toggleMode("login")}>Войти</a>
							</p>
						</div>
					)
				)}
			</div>
		</div>
	);
}

const SuccessfulRegistrationMessage = ({
	successPopupVisibility,
	setSuccessPopupVisibility,
	toggle,
}) => {
	const closeMessage = () => {
		toggle(false);
		setTimeout(() => {
			setSuccessPopupVisibility(false);
		}, 500);
	};

	return (
		<div
			className={`authorization-popup__successful-registration-wrapper ${
				successPopupVisibility ? "" : "block_disabled"
			}`}>
			<div className="authorization-popup__successful-registration-background"></div>
			<div className="authorization-popup__successful-registration">
				<h1>Вам направлено письмо на электронную почту</h1>
				<p>Просим вас подтвердить Ваш профиль</p>
				<FunctionalButton onClick={() => closeMessage(false)}>
					Понятно
				</FunctionalButton>
			</div>
		</div>
	);
};
