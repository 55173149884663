import "./Footer.css";
import { useLocation } from "react-router-dom";

export default function Footer() {
	const location = useLocation();
	return (
		<>
			{location.pathname === "/" && (
				<footer className="footer">
					<div className="footer__content">
						<div className="footer__info-container mobile-app">
							<p>Скачайте мобильное приложение</p>
							<div className="footer__links-container">
								<a href="">
									<img
										src="/images/footer/android-app.svg"
										alt="Приложение в GooglePlay"
									/>
								</a>
								<a href="">
									<img
										src="/images/footer/apple-app.svg"
										alt="Приложение в AppStore"
									/>
								</a>
							</div>
						</div>
						<div className="footer__info-container social">
							<p>Мы в соцсетях</p>
							<div className="footer__links-container">
								<a href="">
									<img
										src="/images/footer/telegram.svg"
										alt="Приложение в AppStore"
									/>
								</a>
								<a href="">
									<img
										src="/images/footer/whatsapp.svg"
										alt="Приложение в AppStore"
									/>
								</a>
								<a href="">
									<img
										src="/images/footer/vk.svg"
										alt="Приложение в AppStore"
									/>
								</a>
							</div>
						</div>
						<div className="footer__info-container about">
							<p>О нас</p>
							<p>Карта сайта</p>
							<p>Партнерам</p>
						</div>
						<div className="footer__info-container support">
							<p>Ответим на ваши вопросы в чате</p>
							<div className="footer__links-container">
								<a href="">
									<img
										src="/images/footer/telegram_support.svg"
										alt="Telegram чат"
									/>
								</a>
								<a href="">
									<img
										src="/images/footer/whatsapp_support.svg"
										alt="Whatsapp чат"
									/>
								</a>
								<a href="">
									<img src="/images/footer/chat_support.svg" alt="Чат" />
								</a>
							</div>
						</div>
					</div>
					<p className="footer__copyright">Copyright © 2024 SPORT CLICK</p>
				</footer>
			)}
		</>
	);
}
