import React, { useState } from "react";
import "./../App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {remont} from "./products";
import {useNavigate} from "react-router-dom";


function App2() {

    const [push, setPush] = useState(['arena', 'school']);
    const navigate = useNavigate();
    const handleNotification = async () => {
        const categoriesString = push.join(',');
        const url = `/api/user/setcategoriesnew?categoriesnew=${categoriesString}`;

        try {
            const response = await fetch(url, {
                method: 'GET', // или 'POST' в зависимости от требований
            });

            if (response.ok) {
                console.log('Успешно отправлено!');
            } else {
                console.error('Ошибка при отправке уведомлений');
            }
        } catch (error) {
            console.error('Ошибка:', error);
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const [sortType, setSortType] = useState(null);
    const [selectedCity, setSelectedCity] = useState("Москва");
    const [cityDropdownOpen, setCityDropdownOpen] = useState(false);
    const openModalOtobr = () => setIsModalOtobrOpen(true);
    const closeModalOtobr = () => setIsModalOtobrOpen(false);
    const [isModalOtobrOpen, setIsModalOtobrOpen] = useState(false);
    const [isGridView, setIsGridView] = useState(true);
    const [viewMode, setViewMode] = useState('grid');
    const toggleView = () => {
        setIsGridView(!isGridView);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [selectedAgeGroup, setSelectedAgeGroup] = useState("Взрослые (>18 лет)");
    const [priceRange, setPriceRange] = useState({ min: "", max: "" });
    const [selectedCondition, setSelectedCondition] = useState("Любое");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedDiscount, setSelectedDiscount] = useState("");
    const cities = ["Москва", "Санкт-Петербург", "Новосибирск", "Екатеринбург", "Казань"];
    const [currentSlides, setCurrentSlides] = useState({});
    const getActiveFiltersCount = () => {
        let count = 0;
        if (selectedCategory && selectedCategory !== "Не выбрано") count++;
        if (priceRange.min) count++;
        if (selectedCondition && selectedCondition !== "Любое") count++;
        if (selectedAgeGroup && selectedAgeGroup !== "Взрослые (>18 лет)") count++;
        for (let key in activeFilters) {
            if (activeFilters[key]) count++;
        }
        return count;
    };
    const settings = (productId) => ({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => handleSlideChange(productId, current), // передаем id товара и текущий слайд
    });
    const handleSlideChange = (productId, current) => {
        setCurrentSlides((prevState) => ({
            ...prevState,
            [productId]: current,
        }));
    };
    const ageGroups = [
        "Взрослые (>18 лет)",
        "Подростки (15-17 лет)",
        "Подростки (12-14 лет)",
        "Дети (12-14 лет)",
        "Дети (8-12 лет)",
        "Дети (5-7 лет)",
    ];
    const conditions = ["Любое", "Новое", "Б/У"];
    const category = ["Не выбрано", "Форма для полевого игрока", "Форма для вратаря", "Тренировочный инвентарь", "Прочее"];
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const openModalMenu = () => setIsModalMenuOpen(true);
    const closeModalMenu = () => setIsModalMenuOpen(false);
    const openFilterModal = () => setIsFilterModalOpen(true);
    const closeFilterModal = () => setIsFilterModalOpen(false);
    const handleApplyFilter = () => {
        closeModal();
    };
    const handleClearSelection = () => {
        setSelectedAgeGroup("");
    };
    const handleCitySelect = (city) => {
        setSelectedCity(city);
        setCityDropdownOpen(false);
    };
    let filteredProducts = remont.filter(product =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        product.city === selectedCity &&
        (selectedAgeGroup === "" || product.ageGroup === selectedAgeGroup) &&
        (selectedCondition === "Любое" || product.condition === selectedCondition) &&
        (selectedCategory.length === 0 || selectedCategory.includes(product.category)) &&
        (!priceRange.min || product.price >= parseInt(priceRange.min)) &&
        (!priceRange.max || product.price <= parseInt(priceRange.max))
    );
    if (sortType === "price-asc") {
        filteredProducts.sort((a, b) => a.price - b.price);
    } else if (sortType === "price-desc") {
        filteredProducts.sort((a, b) => b.price - a.price);
    } else if (sortType === "rating") {
        filteredProducts.sort((a, b) => b.rating - a.rating);
    }else if (sortType === "date") {
        filteredProducts.sort((a, b) => b.dat - a.dat);
    }
    const groupedProducts = [];
    for (let i = 0; i < filteredProducts.length; i += 2) {
        groupedProducts.push(filteredProducts.slice(i, i + 2));
    }
    const [isSortModalOpen, setIsSortModalOpen] = useState(false);
    const openSortModal = () => setIsSortModalOpen(true);
    const closeSortModal = () => setIsSortModalOpen(false);
    const handleApplySort = () => {
        closeSortModal();
    };
    const [isPriceDropdownOpen, setIsPriceDropdownOpen] = useState(false);
    const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
    const [isConditionDropdownOpen, setIsConditionDropdownOpen] = useState(false);
    const togglePriceDropdown = () => setIsPriceDropdownOpen(!isPriceDropdownOpen);
    const toggleCategoryDropdown = () => setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
    const toggleConditionDropdown = () => setIsConditionDropdownOpen(!isConditionDropdownOpen);
    const [activeFilters, setActiveFilters] = useState({
        discount: false,
        online: false,
    });
    const [isFilterActive, setIsFilterActive] = useState(false);
    const handleApplyFilterModal = () => {
        if (selectedCategory.length > 0 || priceRange.min || selectedCondition) {
            setIsFilterActive(true);
        } else {
            setIsFilterActive(false);
        }
        closeFilterModal();
    };
    const handleFilterClick = (filter) => {
        setActiveFilters((prevFilters) => ({
            ...prevFilters,
            [filter]: !prevFilters[filter],
        }));

        if (filter === "adults") {
            setIsModalOpen(true);
        }
    };
    const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
    const [isConditionModalOpen, setIsConditionModalOpen] = useState(false);
    const openPriceModal = () => setIsPriceModalOpen(true);
    const closePriceModal = () => setIsPriceModalOpen(false);
    const openConditionModal = () => setIsConditionModalOpen(true);
    const closeConditionModal = () => setIsConditionModalOpen(false);
    const handleClearPriceSelection = () => setPriceRange({ min: '', max: '' });
    const handleClearConditionSelection = () => setSelectedCondition('Любое');
    const handleApplyPriceFilter = () => {
        // Применяем фильтр по цене
        closePriceModal();
    };
    const handleApplyConditionFilter = () => {
        // Применяем фильтр по состоянию
        closeConditionModal();
    };
    const handleCategoryClick = (category) => {
        setSelectedCategory((prevSelectedCategory) => {
            if (prevSelectedCategory.includes(category)) {
                // Убираем категорию, если она уже выбрана
                return prevSelectedCategory.filter((cat) => cat !== category);
            } else {
                // Добавляем категорию, если она еще не выбрана
                return [...prevSelectedCategory, category];
            }
        });
    };

    const handleBack = () => {
        if (window.history.length > 1) {
            window.history.back();
        } else {
            window.location.href = "/Home";
        }
    };
    const handleResetFilters = () => {
        setSelectedCity("Выберите город");
        setSelectedAgeGroup("Выберите возрастную группу");
        setPriceRange({ min: 0, max: 45000 });
        setSelectedCategory([]);
        setSelectedCondition("Любое");
    };

    const clearSearch = () => {
        setSearchQuery('');
    };

    return (
        <div className="container">
            {/* Первая ступень: Кнопка возврата и заголовок */}
            <div className="step step-1">
                <div>
                    <button className="back-button" onClick={handleBack}></button>
                    <h1>Ремонт формы и заточка</h1>
                </div>


                <div className="icons">
                    <button className="icon-button" ></button>
                    <button className="icon-button"></button> {/* Иконка избранного */}
                    <button className="icon-button" onClick={openModalMenu}></button> {/* Иконка корзины */}
                </div>
            </div>

            {/* Вторая ступень: Инпут поиска, Избранное и Корзина */}
            <div className="step step-2">
                <input
                    type="text"
                    placeholder="Поиск"
                    className="search-input"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <span className={searchQuery ? 'clear-button visible' : 'clear-button'} onClick={clearSearch}>Отменить</span>
            </div>

            {isModalMenuOpen && (
                <div className="modal">
                    <div className="modal-menu">
                        <div className="modal-menu-header">
                            <div className="modal-stroke">Избранные <p>0</p></div>
                            <hr/>
                            <div className="modal-stroke">Размещенные <p>0</p></div>
                        </div>

                        <button className="apply-btn" onClick={closeModalMenu}>Отмена</button>
                    </div>
                </div>
            )}

            {/* Третья ступень: Выбранный город и кнопка выбора города */}
            <div className="step step-3">
                <div className="custom-select">
                    <span className="city"><span></span>
                        {selectedCity}</span>
                    <button
                        className="city-button"
                        onClick={() => setCityDropdownOpen(!cityDropdownOpen)}
                    >
                        <span></span> Выбрать город
                    </button>
                    {cityDropdownOpen && (
                        <div className="dropdown">
                            {cities.map((city) => (
                                <button key={city} onClick={() => handleCitySelect(city)}>
                                    {city}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {/* Четвертая ступень: Активные фильтры со скроллом вправо */}

            {isPriceModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="back-button" onClick={closePriceModal}></button>
                            <h2>Цена</h2>
                            <button className="clear-btn" onClick={handleClearPriceSelection}>Очистить</button>
                        </div>
                        <div className="modal-body">
                            <div className="price-range">
                                <label htmlFor="min-price">Цена от:</label>
                                <input
                                    type="number"
                                    id="min-price"
                                    value={priceRange.min}
                                    onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })}
                                />
                            </div>
                            <div className="price-range">
                                <label htmlFor="max-price">Цена до:</label>
                                <input
                                    type="number"
                                    id="max-price"
                                    value={priceRange.max}
                                    onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="apply-btn" onClick={handleApplyPriceFilter}>Применить фильтр</button>
                        </div>
                    </div>
                </div>
            )}

            {isConditionModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="back-button" onClick={closeConditionModal}></button>
                            <h2>Состояние</h2>
                            <button className="clear-btn" onClick={handleClearConditionSelection}>Очистить</button>
                        </div>
                        <div className="modal-body">
                            {conditions.map((condition) => (
                                <div key={condition} className="radio-group">
                                    <input
                                        type="radio"
                                        id={condition}
                                        name="condition"
                                        value={condition}
                                        checked={selectedCondition === condition}
                                        onChange={(e) => setSelectedCondition(e.target.value)}
                                    />
                                    <label htmlFor={condition}>{condition}</label>
                                </div>
                            ))}
                        </div>
                        <div className="modal-footer">
                            <button className="apply-btn" onClick={handleApplyConditionFilter}>Применить фильтр</button>
                        </div>
                    </div>
                </div>
            )}

            {isFilterModalOpen && (
                <div className="modal modal-fullscreen">
                    <div className="modal-content modal-content-filter">
                        <div className="modal-header">
                            <button className="back-button" onClick={closeFilterModal}></button>
                            <h2 className="modal-title">Фильтры</h2>

                            <a href="" onClick={handleResetFilters} className="clear-btn" style={{textDecoration:"none"}}>Очистить</a>
                        </div>

                        <div className="modal-body">
                            <label style={{fontWeight:700}}>Выбрать город</label>
                            <div className="filter-group">
                                <div className="custom-select">
                                    <div className="dropdown-container">
                                        <div className="dropdown-trigger" onClick={() => setCityDropdownOpen(!cityDropdownOpen)}>
                                            <span className="dropdown-label">{selectedCity}</span>
                                            <span className="dropdown-value" style={{rotate:"90deg"}}>&gt;</span>
                                        </div>
                                    </div>
                                    {cityDropdownOpen && (
                                        <div className="dropdown">
                                            {cities.map((city) => (
                                                <button key={city} onClick={() => handleCitySelect(city)}>
                                                    {city}
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="filter-group" style={{display:"flex", flexDirection:"column", gap:16, marginTop:8}}>
                                <label style={{fontWeight:700}}>Стоимость</label>
                                <div className="price-range">
                                    <input
                                        type="number"
                                        value={priceRange.min}
                                        onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })}
                                        placeholder="От 0"
                                    />
                                    <input
                                        type="number"
                                        value={priceRange.max}
                                        onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })}
                                        placeholder="До 45000"
                                    />
                                </div>
                                <div className="price-slider-container">
                                    <input
                                        type="range"
                                        min="0"
                                        max="22500"
                                        value={priceRange.min}
                                        onChange={(e) => setPriceRange({ ...priceRange, min: Math.min(Number(e.target.value), priceRange.max - 1) })}
                                        className="price-slider"
                                    />
                                    <input
                                        type="range"
                                        min="22500"
                                        max="45000"
                                        value={priceRange.max}
                                        onChange={(e) => setPriceRange({ ...priceRange, max: Math.max(Number(e.target.value), priceRange.min + 1) })}
                                        className="price-slider"
                                    />
                                </div>
                            </div>

                            <div className="filter-group">
                                <div className="radio-group">
                                    {category
                                        .filter((cat) => cat !== "Не выбрано")
                                        .map((cat) => (
                                            <div key={cat} className="switch-group">
                                                <label className="switch-label">
                                                    <span style={{fontWeight:700}}>{cat}</span>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            className="hidden"
                                                            checked={selectedCategory.includes(cat)}
                                                            onChange={() => handleCategoryClick(cat)}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </label>
                                            </div>
                                        ))}

                                </div>
                            </div>

                            <div className="filter-group">
                                <label style={{fontWeight:700}}>Состояние</label>
                                <div className="radio-group">
                                    <div className="form_radio" >
                                        <input
                                            type="radio"
                                            id="new"
                                            name={"filed_" + "condition"}
                                            value="Новое"
                                            checked={selectedCondition === "Новое"}
                                            onChange={() => setSelectedCondition("Новое")}
                                        /><label htmlFor="new">Новое</label>
                                    </div>

                                    <div>
                                        <input
                                            type="radio"
                                            id="used"
                                            name="condition"
                                            value="Б/У"
                                            checked={selectedCondition === "Б/У"}
                                            onChange={() => setSelectedCondition("Б/У")}
                                        /><label htmlFor="used">Б/У</label>
                                    </div>
                                </div>
                            </div>
                            <button className="apply-btn" onClick={handleApplyFilterModal} style={{marginTop: 16}}>Применить фильтр</button>
                        </div>
                    </div>
                </div>
            )}


            {/* Модальное окно для выбора возраста */}
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="back-button" onClick={closeModal}></button>
                            <h2>Возраст</h2>
                            <button className="clear-btn" onClick={handleClearSelection}>Очистить</button>
                        </div>
                        <div className="modal-body">
                            {ageGroups.map((group) => (
                                <div key={group} className="radio-group">
                                    <input
                                        type="radio"
                                        id={group}
                                        name="ageGroup"
                                        value={group}
                                        checked={selectedAgeGroup === group}
                                        onChange={(e) => setSelectedAgeGroup(e.target.value)}
                                    />
                                    <label htmlFor={group}>{group}</label>
                                </div>
                            ))}
                        </div>
                        <div className="modal-footer">
                            <button className="apply-btn" onClick={handleApplyFilter}>Применить фильтр</button>
                        </div>
                    </div>
                </div>
            )}

            {isSortModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="back-button" onClick={closeSortModal}></button>
                            <h2>Сортировка</h2>
                            <button className="clear-btn" onClick={() => setSortType(null)}>Очистить</button>
                        </div>
                        <div className="modal-body">
                            <div className="radio-group" style={{flexDirection: "row"}}>
                                <input
                                    type="radio"
                                    id="price-asc"
                                    name="sortType"
                                    value="price-asc"
                                    checked={sortType === "price-asc"}
                                    onChange={(e) => setSortType(e.target.value)}
                                />
                                <label style={{marginLeft:"0"}} htmlFor="price-asc"> Cначала дешевле</label>
                            </div>
                            <div className="radio-group" style={{flexDirection: "row"}}>
                                <input
                                    type="radio"
                                    id="price-desc"
                                    name="sortType"
                                    value="price-desc"
                                    checked={sortType === "price-desc"}
                                    onChange={(e) => setSortType(e.target.value)}
                                />
                                <label style={{marginLeft:"0"}} htmlFor="price-desc"> Cначала дороже</label>
                            </div>
                            <div className="radio-group" style={{flexDirection: "row"}}>
                                <input
                                    type="radio"
                                    id="date"
                                    name="sortType"
                                    value="date"
                                    checked={sortType === "date"}
                                    onChange={(e) => setSortType(e.target.value)}
                                />
                                <label style={{marginLeft:"0"}} htmlFor="price-desc">По дате</label>
                            </div>
                            <div className="radio-group" style={{flexDirection: "row"}}>
                                <input
                                    type="radio"
                                    id="rating"
                                    name="sortType"
                                    value="rating"
                                    checked={sortType === "rating"}
                                    onChange={(e) => setSortType(e.target.value)}
                                />
                                <label style={{marginLeft:"0"}} htmlFor="rating">По рейтингу</label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="apply-btn" onClick={handleApplySort}>Применить сортировку</button>
                        </div>
                    </div>
                </div>
            )}


            {/* Пятая ступень: Кастомный селект для сортировки */}
            <div className="step step-5">
                <div className="custom-select">
                    <button className="sort-button" onClick={openSortModal}><span></span> Сортировка</button>
                </div>
                <button className="notify-button" onClick={openModalOtobr}><span></span>  Формат отображения</button>
            </div>
            {isModalOtobrOpen && (
                <div className="modal">
                    <div className="modal-menu">
                        <div className="modal-menu-header slsl" style={{height:"max-content"}}>
                            <h2>Отображение объявлений</h2>
                            <div className="modal-stroke" style={{display:"flex", flexDirection:"row", gap:"8px", justifyContent:"inherit"}}>
                                <input
                                    type="radio"
                                    id="grid-view"
                                    name="viewMode"
                                    value="grid"
                                    checked={viewMode === 'grid'}
                                    onChange={() => setViewMode('grid')}
                                />
                                <label>Сеткой <p></p></label>
                            </div>
                            <div className="modal-stroke" style={{display:"flex", flexDirection:"row", gap:"8px", justifyContent:"inherit"}}>
                                <input
                                    type="radio"
                                    id="solo-view"
                                    name="soloMode"
                                    value="solo"
                                    checked={viewMode === 'solo'}
                                    onChange={() => setViewMode('solo')}
                                />
                                <label>По одному <p></p></label>
                            </div>
                            <div className="modal-stroke" style={{display:"flex", flexDirection:"row", gap:"8px", justifyContent:"inherit"}}>
                                <input
                                    type="radio"
                                    id="list-view"
                                    name="viewMode"
                                    value="list"
                                    checked={viewMode === 'list'}
                                    onChange={() => setViewMode('list')}
                                />
                                <label>Вертикально <p></p></label>
                            </div>
                        </div>

                        <button className="apply-btn" onClick={closeModalOtobr}>Отмена</button>
                    </div>
                </div>
            )}
            {/* Список товаров с горизонтальным скроллом и двумя вертикальными рядами */}
            <div className={`product-scroll-container ${viewMode === 'grid' ? 'grid-view' : viewMode === 'list' ? 'list-view' : 'solo-view'}`}>
                <div className="products-row" >
                    {groupedProducts.length > 0 ? (
                        groupedProducts.map((group, index) => (
                            <div className="product-column" key={index}>
                                {group.map((product) => (
                                    <div
                                        className="product"
                                        key={product.id}
                                        onClick={() => {
                                            console.log('Navigating to product:', product.id, 'with products:', groupedProducts);
                                            navigate(`/equipment-repair/${product.id}`, { state: { products: groupedProducts } });
                                        }}


                                    >
                                        <Slider {...settings(product.id)}>
                                            {product.images && product.images.map((image, i) => (
                                                <img src={image} alt={`Product Image ${i + 1}`} key={i} />
                                            ))}
                                        </Slider>
                                        <div className="slider-counter">
                                            {(currentSlides[product.id] || 0) + 1} из {product.images.length}
                                        </div>

                                        <div className="product-info" style={{    marginTop: "-4px"}}>
                                            <h2>{product.name}</h2>
                                            <p style={{fontWeight:400}}>{product.location}</p>
                                            <p style={{fontWeight:400, fontSize:14}}>{product.category}</p>
                                            <p className="rating">
                                                <span>{product.rating}</span> (0)
                                            </p>
                                            <span className="product-izb"></span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <div className="no-products">
                            <p>По выбранным фильтрам объявлений нет</p>
                        </div>
                    )}
                </div>

            </div>

        </div>
    );
}

export default App2;