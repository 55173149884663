import { NavLink } from "react-router-dom";
import "./MainPage.css";

export default function MainPage() {
	return (
		<main>
			<div className="title">
				<h2>Вместо всего что ниже, здесь будет главная страница</h2>
				<h3>Демонстрация разделов:</h3>
			</div>
			<div className="links">
				<NavLink to="/equipment">Купить форму и инвентарь</NavLink>
				<NavLink to="/tournament">Записаться на турнир</NavLink>
				<NavLink to="/insurance">Страхование</NavLink>
				<NavLink to="/goalkeepers">Вратари</NavLink>
				<NavLink to="/referees">Судьи</NavLink>
				<NavLink to="/training">Бронирование тренировок</NavLink>
				<NavLink to="/bloggers">Блоггеры</NavLink>
				<NavLink to="/equipment-repair">Ремонт формы и заточка</NavLink>
				<NavLink to="/equipment-tailoring">Пошив формы</NavLink>
				<NavLink to="/dry-cleaning">Химчистка</NavLink>
				<NavLink to="/rent">Аренда льда и зала</NavLink>
				<NavLink to="/coaches">Тренеры</NavLink>
			</div>
		</main>
	);
}
