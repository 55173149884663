import axios from "axios";

export async function registerUserAction(authData) {
	const formattedBirthDate = authData.regBirthDate.split(".").reverse().join("-");
	try {
		const response = await axios.post(
			process.env.REACT_APP_PUBLIC_API_URL +
				"/api/Authorization/SingIn/Register",
			{
				username: authData.regEmail.toString(),
				email: authData.regEmail.toString(),
				password: authData.regPassword.toString(),
				name: authData.regName.toString(),
				surname: authData.regSurname.toString(),
				patronymic: "",
				dateOfBirth: formattedBirthDate.toString(),
				sendingMail: true,
			},
			{
				headers: {
					accept: "*/*",
					"Content-Type": "application/json",
				},
			}
		);
		return response;
	} catch (err) {
		console.log(err);
		throw err;
	}
}

export async function logUserAction(authData) {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_PUBLIC_API_URL}/api/Authorization/LogIn/Login`,
			{
				params: {
					usernameOrEmail: authData.logEmail.toString(),
					password: authData.logPassword.toString(),
					rememberUser: true
				},
				headers: {
					accept: "*/*",
				},
			}
		);
		return response;
	} catch (err) {
		console.log(err);
		throw err;
	}
}
